import React, { useState, useEffect } from 'react';
import './App.css';
import Card from './Card';
import Detail from './Detail';
import Navbar from './Navbar';
import data from './data.json'; // Assume this is the path to your data
import ReactPaginate from 'react-paginate';
import Login from './Login';
import ScrollingPlaylists from './ScrollingPlaylists';
import Marquee from './Marquee'; // Import the Marquee component



const App = () => {
  const [selectedPost, setSelectedPost] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0); // Track current page
  const [filteredData, setFilteredData] = useState([]);
  const [originalData, setOriginalData] = useState([]); // Keep the original data in state
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedContentType, setSelectedContentType] = useState('All'); // New state for premium/free filter
  const [userLoc, setUserCity] = useState([]); // To store user city

  // Extract unique categories from data
  const categories = ['All', ...new Set(data.map(item => item.category).filter(ele => ele))];

  const shuffleArray = (array) => {
    let shuffled = array.slice();
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const setShowLoginFunc = () => {
    setShowLogin(true);
  }

  const handleCardClick = (post) => {
    if (post.isPremium && !isLoggedIn) {
      setShowLogin(true);
    } else {
      setSelectedPost(post); // Open the detail view
    }
  };

  const handleBackClick = () => {
    setSelectedPost(null); // Close the detail view and return to the list
    setShowLogin(false);
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(0); // Reset to page 1 on new search
  };

  const handleLogin = (status) => {
    setIsLoggedIn(status);
    setShowLogin(false);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setCurrentPage(0); // Reset to page 1 when changing category
  };

  const handleContentTypeChange = (event) => {
    setSelectedContentType(event.target.value);
    setCurrentPage(0); // Reset to page 1 when changing content type
  };

  const handleRefreshList = () => {
    const shuffledData = shuffleArray(originalData); // Shuffle only originalData
    setFilteredData(shuffledData); // Update the filteredData with the shuffled list
    setCurrentPage(0); // Reset to page 1 after refreshing
  };

  useEffect(() => {
    const lowercasedFilter = searchTerm.toLowerCase();
    const filtered = originalData.filter((item) =>
      item.title.toLowerCase().includes(lowercasedFilter) &&
      (selectedCategory === 'All' || item.category === selectedCategory) &&
      (selectedContentType === 'All' || (selectedContentType === 'Premium' ? item.isPremium : !item.isPremium)));
    setFilteredData(filtered);
  }, [searchTerm, selectedCategory, selectedContentType, originalData]);

  useEffect(() => {
    // Set the initial video list without shuffling
    setOriginalData(data);
    setFilteredData(data); // Show all videos initially
  }, []);

  // Function to get the city name using Geolocation and OpenCage API
  const fetchCityName = async (latitude, longitude) => {
    const API_KEY = 'c9b2f891c9e14d8e84e97bca4d8323d6'; // Replace with your OpenCage API Key
    const response = await fetch(
      `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${API_KEY}`
    );
    const data = await response.json();
    return data.results[0].components || ''; // Return the city name
  };

  // Get user's location and store in localStorage
  useEffect(() => {
    const savedLocation = localStorage.getItem('userLocation');

    if (savedLocation) {
      setUserCity(JSON.parse(savedLocation)); // Use the saved location
    } else {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const city = await fetchCityName(latitude, longitude);
          const locationArray = [city.town, city.state_district, city.state];
          
          setUserCity(locationArray); // Set the detected city

          // Save location to localStorage
          localStorage.setItem('userLocation', JSON.stringify(locationArray));

          fetch('https://storelocationdata.onrender.com/save-location', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ latitude, longitude }),
          })
            .then((response) => response.json())
            .then((data) => {
              console.log('Location saved:', data);
            })
            .catch((error) => {
              console.error('Error saving location:', error);
            });
        },
        (error) => {
          console.error("Error getting location:", error);
          setUserCity(''); // Fallback if location is not available
        }
      );
    }
  }, []);

  const postsPerPage = 50;
  const currentPosts = filteredData.slice(
    currentPage * postsPerPage,
    (currentPage + 1) * postsPerPage
  );

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected); // Update current page on pagination click
  };

  useEffect(() => {
    const loggedIn = localStorage.getItem('isLoggedIn');
    setIsLoggedIn(loggedIn === 'true');
  }, []);

  return (
    <div className="app">
      <Navbar onSearch={handleSearch} />
      
      <div style={{
        margin:"2rem 0"
      }}><ScrollingPlaylists/></div>
            <Marquee text="This is a moving text that scrolls from right to left!" />

      


      {!showLogin && (
        <>
          <div className="filters">
            <div className='filterBox'>
              <label htmlFor="category">Category</label>
              <select
                id="category"
                value={selectedCategory}
                onChange={handleCategoryChange}
              >
                {categories.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
            <div className='filterBox'>
              <label htmlFor="content-type">Subscription</label>
              <select
                id="content-type"
                value={selectedContentType}
                onChange={handleContentTypeChange} // Define this function to handle content type changes
              >
                <option value="All">All</option>
                <option value="Free">Free</option>
                <option value="Premium">Premium</option>
              </select>
            </div>
          </div>
        </>
      )}
      {selectedPost ? (
        <Detail
          post={selectedPost}
          onBackClick={handleBackClick}
          data={data}
          handleCardClick={handleCardClick}
          isLoggedIn={isLoggedIn}
          setShowLogin={setShowLoginFunc}
          handleLogin={handleLogin} // Ensure this is passed correctly
        />
      ) : showLogin ? (
      <>  
      <div className="detail-container">
      <button className="back-button" onClick={handleBackClick}>
          Back
        </button>
        </div>
      <Login onLogin={handleLogin} /> </> 
      ) : (
        <>
          <div className="card-list">
            {currentPosts.map((post,i) => {
              let str=post.title
              if(userLoc[1]) {
                if(i===7 || i===15 || i===29 || i===2 || i===8||i===9 || i===10 || i===11||i===14){

                  if(userLoc[0]){
                  str = post.title+" in "+ (userLoc[0]?.includes("Unknown" || null) ? userLoc[1] : userLoc[0])?.toLowerCase();
                  }
                  else{
                    str = post.title+" in "+ userLoc[1]?.toLowerCase();
                  }
                }
                if(i===4){
                  str = post.title+" in "+ ((userLoc[0]?.includes("Unknown")|| (userLoc[0] == null)) ? userLoc[1] : userLoc[0])?.toLowerCase()+" got leaked";
                }
                if(i===11){
                  str = post.title+" in "+ ((userLoc[1]?.slice(0, -8).toLowerCase()) ? (userLoc[1]?.slice(0, -8).toLowerCase()) : "maharashtra - trending now");
                }
                if(i===5){
                  str = userLoc[1]?.slice(0, -8)+" " +post.title;
                }
                if(i===12){
                  str = ((userLoc[0]?.includes("Unknown")|| (userLoc[0] == null)) ? userLoc[1] : userLoc[0])+" " +post.title;
                }
              }
              return (
              <Card
                key={post.title}
                imageSrc={post.imageSrc}
                title={str}
                subTitle={post.subTitle}
                category={post.category}
                postImages={post.postImages}
                isPremium={post.isPremium}
                onClick={() => handleCardClick(post)}
              />
            )})}
          </div>
          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(filteredData.length / postsPerPage)}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            forcePage={currentPage}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        </>
      )}
    </div>
  );
};

export default App;
